( function ( $, undefined ) {
	var Field = acf.models.SelectField.extend( {
		type: 'user',
	} );

	acf.registerFieldType( Field );

	acf.addFilter(
		'select2_ajax_data',
		function ( data, args, $input, field, select2 ) {
			if ( ! field ) {
				return data;
			}

			const query_nonce = field.get( 'queryNonce' );
			if ( query_nonce && query_nonce.length ) {
				data.user_query_nonce = query_nonce;
			}

			return data;
		}
	);
} )( jQuery );
